<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="title" width="1200px" @closed="form = null">
    <template v-if="form">
      <template v-if="editable">
        <el-form ref="form" :model="form" :rules="rules" label-width="84px" label-position="right" label-suffix=":">
          <div class="h">
            <el-form-item label="入库库房" v-show="form.formType === 5">
              <div style="width: 180px;">{{form.warehouseName || "请输入调拨单号"}}</div>
            </el-form-item>
            <el-form-item label="入库库房" prop="warehouseId" v-show="form.formType !== 5">
              <quick-select v-model="form.warehouseId" :label.sync="form.warehouseName" auto-select-single-option displayField="name" url="api/warehouse" filterable placeholder="请选择库房" style="width: 180px;" />
            </el-form-item>
            <el-form-item label="入库时间" prop="formTime" label-width="120px">
              <el-date-picker v-model="form.formTime" :clearable="false" value-format="timestamp" style="width: 180px;" readonly />
            </el-form-item>
            <el-form-item prop="manager" label="入库人" label-width="120px">
              <el-input v-model="form.manager" :maxlength="25" style="width: 180px;" />
            </el-form-item>
          </div>
          <!-- <div class="h">
            <el-form-item label="入库类型" prop="formType">
              <el-select v-model="form.formType" filterable placeholder="请选择入库类型" @change="handleFormTypeChange" style="width: 180px;">
                <el-option v-for="(v, k) in storageTypes" :key="k" :value="parseInt(k)" :label="v" />
              </el-select>
            </el-form-item>
            <template v-if="!mode">
              <el-form-item prop="dependFormCode" label="发货通知单号" label-width="120px" v-if="form.formType === 1">
                <el-input v-model.trim="form.dependFormCode" :readonly="referenceLoading" placeholder="输入发货通知单号查询并填入明细" @change="loadConsignmentOrder" style="width: 480px;">
                  <el-button slot="append" icon="el-icon-search" :loading="referenceLoading" />
                </el-input>
              </el-form-item>
              <el-form-item prop="dependFormCode" label="退货单号" label-width="120px" v-else-if="form.formType === 3">
                <el-input v-model.trim="form.dependFormCode" :readonly="referenceLoading" placeholder="输入退货单号查询并填入明细" @change="loadReturnOrder" style="width: 480px;">
                  <el-button slot="append" icon="el-icon-search" :loading="referenceLoading" />
                </el-input>
              </el-form-item>
              <el-form-item prop="dependFormCode" label="调拨单号" label-width="120px" v-else-if="form.formType === 5">
                <el-input v-model.trim="form.dependFormCode" :readonly="referenceLoading" placeholder="输入调拨单号查询并填入明细" @change="loadAllocationOrder" style="width: 480px;">
                  <el-button slot="append" icon="el-icon-search" :loading="referenceLoading" />
                </el-input>
              </el-form-item>
            </template>
          </div>-->
          <!-- <el-form-item label="收货单号">
            <el-input v-model.trim="form.dependFormCode" :readonly="referenceLoading" placeholder="输入销售出库单，舒达以X开头的单号" @change="loadConsignmentOrder" style="width: 480px;">
              <el-button slot="append" icon="el-icon-search" :loading="referenceLoading" />
            </el-input>
          </el-form-item>-->
          <!-- <el-form-item label="收货单号" >
            <datagrid-picker reference="选择收货单" reference-type="info" url="api/orderSendForm/erp/page" :popper-width="800" :show-index="false" pageLayout="prev, pager, next,sizes" @change="handleFormCode">               
                <el-table-column prop="formCode" label="单号" width="140" />
                <el-table-column label="单据日期" width="100" :formatter="r => { return r.realSendTime?new Date(r.realSendTime).format('yyyy/MM/dd'):new Date(r.createAt).format('yyyy/MM/dd'); }" />
                <el-table-column prop="goodsPriceAmount" label="单据金额" width="120" align="right" :formatter="$price" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])"/>
                <el-table-column prop="receiverName" label="收货人" min-width="200" />
                <el-table-column label="收货地址" min-width="240" show-overflow-tooltip>
                  <template slot-scope="scope">{{scope.row.recProvince}}{{scope.row.recDistrict}}{{scope.row.recCity}}{{scope.row.recAddress}}</template>
                </el-table-column>
            </datagrid-picker>
            <el-select v-model="form.sourceType" placeholder="单据类型" style="width: 120px;" @change="loadConsignmentOrder">
              <el-option v-for="(v, i) in sourceType" :key="i" :label="v" :value="i" />
            </el-select>
            <el-input v-model.trim="form.dependFormCode" :readonly="referenceLoading" :placeholder="handleTips(form.sourceType)" @change="loadConsignmentOrder" style="width: 480px;">
              <el-button slot="append" icon="el-icon-search" :loading="referenceLoading" />
            </el-input>
          </el-form-item>-->
          <el-form-item label="入库明细" style="margin-bottom: 12px !important; z-index: 2;" required v-if="form.formType === 0">
            <sku-selector request-url="api/goods/sku" :exclude-keys="excludeGoods" @submit="handleGoodsAdd" />
          </el-form-item>
          <el-form-item prop="items" :label="form.formType === 0 ? '' : '入库明细'">
            <el-table :data="form.items" class="table-outdent" show-summary :summary-method="getSummaries">
              <el-table-column prop="code" label="商品编码" min-width="130" />
              <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
              <el-table-column prop="goodsName" label="名称" min-width="160" />
              <el-table-column prop="specs" label="规格" min-width="160" :formatter="$goodsSpecConvert" />
              <!-- <el-table-column prop="brandName" label="品牌" width="120" /> -->
              <el-table-column key="_list_goodsCount" prop="goodsCount" label="应入数量" width="100" align="center" v-if="form.formType !== 0" />
              <el-table-column prop="realCount" label="入库数量" width="100">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.realCount" :min="0.01" :max="9999" :step="1" :precision="2" size="mini" controls-position="right" @change="$checkNaN(scope.row,'realCount',1)" style="width: 100%;" v-if="form.formType === 0 || form.formType === 1" />
                  <span v-else>{{scope.row.realCount}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="info" label="备注" width="160">
                <template slot-scope="scope">
                  <el-input type="textarea" v-model="scope.row.info" rows="1" />
                </template>
              </el-table-column>
              <el-table-column key="_list_command" width="40" v-if="form.formType === 0">
                <div class="row-commands" slot-scope="scope">
                  <el-button type="text" size="mini" @click="handleGoodsRemove(scope.row)">移除</el-button>
                </div>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item prop="info" label="摘要">
            <el-input type="textarea" v-model="form.info" :maxlength="100" :rows="3" resize="none" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button :loading="saving" type="primary" @click="doSave" :disabled="submiting">保存</el-button>
          <el-button :loading="submiting" type="danger" @click="doSubmit" :disabled="saving" v-if="form.id">保存并入库</el-button>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" label-width="68px" label-suffix=":" label-position="right">
          <div class="h sb">
            <div>
              <form-info-item label="入库单号">{{form.formCode}}</form-info-item>
              <form-info-item label="入库库房">{{form.warehouseName}}</form-info-item>
              <form-info-item label="入库时间">{{new Date(form.formTime).format('yyyy/MM/dd')}}</form-info-item>
            </div>
            <div>
              <form-info-item label="入库方式">{{storageViewTypes[form.formType]}}</form-info-item>
              <form-info-item label="发货单号">{{form.dependFormCode}}</form-info-item>
              <form-info-item label="入库人" v-if="form.manager">{{form.manager}}</form-info-item>
            </div>
          </div>
          <el-table border :data="form.items" highlight-current-row empty-text="该订单没有入库的商品" show-summary :summary-method="getSummaries" style="margin: 8px 0;">
            <el-table-column prop="code" label="商品编码" min-width="130" />
            <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
            <el-table-column prop="goodsName" label="名称" min-width="240" />
            <el-table-column prop="specs" label="规格" min-width="120" :formatter="$goodsSpecConvert" />
            <!-- <el-table-column prop="brandName" label="品牌" width="120" /> -->
            <!-- <el-table-column prop="seriesName" label="系列" width="120" /> -->
            <el-table-column prop="goodsCount" label="应入数量" width="100" align="center" v-if="form.formType !== 0" />
            <el-table-column prop="realCount" label="入库数量" width="100" align="right" />
            <el-table-column label="关联单号" width="100">
              <template slot-scope="scope">{{scope.row.dependFormCode}}</template>
            </el-table-column>
            <el-table-column label="备注" width="100" show-overflow-tooltip>
              <template slot-scope="scope">{{scope.row.info}}</template>
            </el-table-column>
          </el-table>
          <form-info-item label="摘要">{{form.info}}</form-info-item>
          <el-divider />
          <div class="h c">
            <form-info-item label="当前状态" class="flex">
              <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
            </form-info-item>
            <el-button type="text" @click="dialog = false">取消</el-button>
            <!-- <el-button type="primary" @click="doPrint">打印</el-button> -->
          </div>
        </el-form>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import {
  get,
  add,
  addByPurchaseConsignmentOrder,
  edit,
  finish,
} from "@/api/storageManage";
import { mapGetters } from "vuex";
import request from "@/utils/request";
import skuSelector from "@/views/assembly/skuSelect";
import checkPermission from "@/utils/permission";

export default {
  components: { skuSelector },
  data() {
    return {
      loading: false,
      referenceLoading: false, //参考单据加载（如加载发货通知单、退货单等）
      saving: false,
      submiting: false,
      searching: false,
      dialog: false,
      mode: null,
      referenceOrder: null,
      goodsQueryDefine: {
        keyword: "",
      },
      storageTypes: {
        1: "采购入库",
        3: "退货入库",
        // 5: "调拨入库",
        0: "其他",
      },
      storageViewTypes: {
        1: "采购入库",
        3: "退货入库",
        5: "调拨入库",
        0: "其他",
      },
      status: [
        { label: "编辑中", type: "info" },
        { label: "已入库", type: "success" },
      ],
      form: null,
      sourceType: {
        saleout: "销售出库单",
        consignCode: "发货通知单",
        orderNo: "采购订单",
      },
      rules: {
        formTime: [
          { required: true, message: "请选择入库日期", trigger: "change" },
        ],
        warehouseId: [
          { required: true, message: "请选择入库库房", trigger: "change" },
        ],
        items: [
          { required: true, message: "请填写单据或者添加入库明细" },
          { type: "array", min: 1, message: "请填写单据或者添加入库明细" },
        ],
        dependFormCode: [{ required: true, message: "请输入相应的单号" }],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    excludeGoods() {
      return (this.form.items || []).map((o) => {
        return o.goodsId;
      });
    },
    title() {
      let str = "加载中…";
      if (this.form) {
        return this.editable
          ? this.form.id
            ? "编辑入库单"
            : "新建入库单"
          : "查看入库单";
      }
      return str;
    },
    editable() {
      return this.form && this.form.status === 0;
    },
  },
  methods: {
    checkPermission,
    handleTips(sourceType) {
      if (sourceType === "saleout") {
        return "舒达以X开头的单号";
      }
      if (sourceType === "consignCode") {
        return "金可儿以SF开头的单号";
      }
      return "请输入相应的采购单号";
    },
    handleFormCode(v, o, res) {
      if (res) {
        this.form.dependFormCode = res.formCode;
        this.loadConsignmentOrder();
      }
    },
    handleFormTypeChange() {
      this.form.dependFormId = "";
      this.form.dependFormCode = "";
      this.form.items = [];
      if (this.form.formType === 5) {
        this.form.warehouseId = null;
        this.form.warehouseName = "";
      }
    },
    loadConsignmentOrder() {
      this.form.items = [];
      if (this.form && this.form.dependFormCode) {
        let convert = (list) => {
          this.form.items = (list || []).map((o) => {
            return {
              id: o.id,
              brandName: o.brandName,
              _receivables: (o.goodsCount || 0) - (o.receiveCount || 0),
              receiveCount: o.receiveCount || 0,
              goodsCount: o.goodsCount || 0,
              realCount: o.goodsCount,
              goodsId: o.goodsId,
              goodsName: o.goodsName,
              seriesName: o.seriesName,
              dependFormCode: o.dependFormCode,
              code: o.code,
              erpCode: o.erpCode,
              specs: o.specName,
              info: o.description,
            };
          });
        };

        this.referenceLoading = true;
        request({
          url: "api/orderSendForm/erpCode/" + this.form.dependFormCode,
          method: "get",
          params: { sourceType: this.form.sourceType },
        })
          .then((res) => {
            if (res) {
              this.form.dependFormId = res.id;
              this.form.erpCode = res.formCode;
              // this.form.entId = res.entId;
              convert(res.items);
            } else {
              this.$alert("没有找到对应的发货单", "系统提示", {
                type: "warning",
              });
            }
          })
          .finally((err) => {
            this.referenceLoading = false;
          });
      }
    },
    loadReturnOrder() {
      this.form.items = [];
      if (this.form && this.form.dependFormCode) {
        this.referenceLoading = true;
        request({
          url: "api/orderBackForm/getByCode/" + this.form.dependFormCode,
          method: "get",
        })
          .then((res) => {
            if (res) {
              this.form.dependFormId = res.id;
              this.form.items = (res.items || []).map((o) => {
                return {
                  brandName: o.goodsBrandName,
                  _receivables: o.count,
                  goodsCount: o.count,
                  goodsId: o.goodsId,
                  goodsName: o.goodsName,
                  seriesName: o.goodsSeriesName,
                  specs: o.goodsSpec,
                };
              });
            } else {
              this.$alert("没有找到对应的退货单", "系统提示", {
                type: "warning",
              });
            }
          })
          .finally((err) => {
            this.referenceLoading = false;
          });
      }
    },
    loadAllocationOrder() {
      this.form.items = [];
      if (this.form && this.form.dependFormCode) {
        this.referenceLoading = true;
        request({
          url: "api/allocationForm/findByCode",
          method: "post",
          data: {
            formCode: this.form.dependFormCode,
            status: 3,
          },
        })
          .then((res) => {
            if (res) {
              this.form.dependFormId = res.id;
              this.form.items = (res.items || []).map((o) => {
                return {
                  brandName: o.goodsBrandName,
                  realCount: o.count,
                  goodsCount: o.count,
                  goodsId: o.goodsId,
                  goodsName: o.goodsName,
                  seriesName: o.goodsSeriesName,
                  code: o.code,
                  erpCode: o.erpCode,
                  specs: o.goodsSpec,
                };
              });
            } else {
              this.$alert("没有找到对应的调拨单", "系统提示", {
                type: "warning",
              });
            }
          })
          .finally((err) => {
            this.referenceLoading = false;
          });
      }
    },
    doSave(callback) {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          this.saving = true;
          let form, action;
          if (this.form.formType === 1) {
            action = addByPurchaseConsignmentOrder;
            form = JSON.parse(JSON.stringify(this.referenceOrder));
            form.warehouseId = this.form.warehouseId;
            form.items = this.form.items.map((o) => {
              return {
                goodsId: o.goodsId,
                goodsName: o.goodsName,
                brandName: o.brandName,
                seriesName: o.seriesName,
                specName: o.specs,
                goodsCount: o.goodsCount,
                code: o.code,
                erpCode: o.erpCode,
                currentReceiveCount: o.realCount,
                dependFormCode: o.dependFormCode,
                info: o.info,
              };
            });
          } else {
            form = this.form;
            action = this.form.id ? edit : add;
          }
          if (!action) return;
          action(form)
            .then((res) => {
              if (this.form.formType === 1) {
                this.$parent.init();
                this.dialog = false;
              } else {
                if (res) this.resetForm(res);
                if (!this.mode) this.$parent.init();
                if (typeof callback === "function") callback();
                else
                  this.$notify({
                    title: `保存入库单成功`,
                    type: "success",
                    duration: 2500,
                  });
              }
            })
            .finally((_) => {
              this.saving = false;
            });
        });
    },
    doSubmit() {
      if (this.form && this.form.id) {
        this.$confirm("您确定要进行入库吗？", "入库确认", {
          type: "warning",
          dangerouslyUseHTMLString: true,
        }).then((res) => {
          this.doSave((_) => {
            this.submiting = true;
            finish(this.form.id)
              .then((res) => {
                this.$notify({
                  title: "入库确认成功",
                  type: "success",
                  duration: 2500,
                });
                switch (this.mode) {
                  case "purchase":
                    this.$alert(
                      `该采购单已经入库成功，入库单号为：${this.form.formCode}。<br />在“库房管理”中可查看入库单或库存情况。`,
                      "入库提示",
                      {
                        dangerouslyUseHTMLString: true,
                      }
                    );
                    break;
                  default:
                    this.$parent.init();
                    break;
                }
                this.dialog = false;
              })
              .finally((_) => {
                this.submiting = false;
              });
          });
        });
      }
    },
    doPrint() {},
    resetForm(form, type, referenceOrder) {
      this.mode = null;
      this.referenceOrder = referenceOrder;
      if (form && form.id) {
        this.loading = true;
        get(form.id)
          .then((res) => {
            this.form = res;
          })
          .finally((_) => {
            this.loading = false;
          });
      } else {
        this.form = Object.assign(
          {
            status: 0,
            formTime: new Date().getTime(),
            manager: this.user.userRealName,
            warehouseId: null,
            warehouseName: "",
            formType: type || 0,
            inoutType: 0,
            info: null,
            sourceType: "saleout",
            dependFormCode: referenceOrder ? referenceOrder.formCode : "",
            items: [],
          },
          form || {}
        );
        if (referenceOrder) {
          switch (type) {
            case 1:
              this.loadConsignmentOrder();
              break;
            case 3:
              this.loadReturnOrder();
              break;
            case 5:
              this.form.warehouseId = referenceOrder.inWarehouseId;
              this.loadAllocationOrder();
              break;
          }
        }
      }
      this.dialog = true;
    },
    handleGoodsAdd(list) {
      if (list && list.length) {
        let ds = this.form.items || [];
        list.forEach((o) => {
          ds.push({
            brandName: o.brandName,
            realCount: 1,
            goodsId: o.id,
            code: o.code,
            erpCode: o.erpCode,
            goodsName: o.name,
            seriesName: o.seriesName,
            specs: o.specs,
          });
        });
        this.form.items = ds;
        this.$refs.form && this.$refs.form.clearValidate();
      }
    },
    handleGoodsRemove(row) {
      if (this.form && this.form.items && this.form.items.length) {
        let inx = this.form.items.findIndex((o) => {
          return o.goodsId === row.goodsId;
        });
        if (inx >= 0) this.form.items.splice(inx, 1);
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (index === 1) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += "";
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
  },
};
</script>
